<style>
.input-readonly > div > .vs-inputx {
  background-color: #e6e6e6;
}
</style>
<template>
  <div class="vx-col w-5/6 ml-auto mr-auto">
    <span>
      <h4 class="mb-5">Debit Note Claim</h4>
    </span>
    <div class="vx-row mb-2">
      <div class="vx-col w-1/2">
        <vs-input
          class="w-full input-readonly"
          label="Supplier"
          name="Supplier"
          v-model="supplier"
          readonly
        />
      </div>
      <div class="vx-col w-1/2">
        <vs-input
          class="w-full input-readonly"
          label="Dn Number"
          name="Dn Number"
          v-model="dn_number"
          readonly
        />
      </div>
    </div>

    <div class="vx-row mb-2">
      <div class="vx-col w-1/2">
        <vs-input
          class="w-full input-readonly"
          label="Ownership"
          name="Ownership"
          v-model="ownership.selected"
          readonly
        />
      </div>
      <div class="vx-col w-1/2" v-if="dataDN.source == 'Import Claim'">
        <vs-input
          class="w-full input-readonly"
          label="DN Type"
          name="DN Type"
          v-model="dataDN.type"
          readonly
        />
      </div>
      <div class="vx-col w-1/2" v-else>
        <vs-input
          class="w-full input-readonly"
          label="DN Type"
          name="DN Type"
          v-model="dnType.selected"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-1/2">
        <vs-input
          class="w-full"
          label="Reference Code"
          name="Reference Code"
          v-model="reference_code"
          placeholder="Reference Code"
        />
      </div>
      <div class="vx-col w-1/2">
        <vs-input
          class="w-full input-readonly"
          label="Dn Date"
          name="Dn Date"
          v-model="dn_date"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-1/2">
        <vs-input
          class="w-full input-readonly"
          readonly
          label="Assignment Code"
          name="Assignment Code"
          v-model="assignment_code"
          placeholder="Assignment Code"
        />
      </div>
      <div class="vx-col w-1/2">
        <vs-input
          class="w-full input-readonly"
          label="Dn Posting Date"
          name="Dn Posting Date"
          v-model="dn_posting_date"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-1/2">
        <vs-input
          class="w-full input-readonly"
          readonly
          label="Supplier CN/SOA Code"
          name="Supplier CN/SOA Code"
          v-model="cn_number"
          placeholder="Supplier CN/SOA Code"
        />
      </div>
      <div class="vx-col w-1/2">
        <vs-input
          class="w-full input-readonly"
          label="VAT OUT"
          name="VAT OUT"
          v-model="tax.selected"
          readonly
        />
      </div>
    </div>

    <div class="vx-row mb-2">
      <div class="vx-col w-1/2">
        <vs-input
          class="w-full input-readonly"
          label="Supplier CN Date"
          name="Supplier CN Date"
          v-model="cn_date"
          readonly
        />
      </div>
      <div class="vx-col w-1/2">
        <label class="vs-input--label">Settlement Method</label>
        <multiselect
          class="selectExample"
          v-model="settlement.selected"
          :options="settlement.options"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          label="name"
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">{{ dt.option }}</span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">{{ dt.option }}</span>
            </div>
          </template>
        </multiselect>
        <span
          class="text-danger text-sm"
          v-if="settlement.selected == null && status_submit"
          >This field is required</span
        >
      </div>
    </div>

    <div class="vx-row mb-2" v-if="dataDN.source == 'Import Claim'">
      <div class="vx-col w-1/2">
        <vs-input
          class="w-full input-readonly"
          readonly
          label="DPP"
          name="DPP"
          v-model="dataDN.dpp"
          placeholder="DPP"
        />
      </div>
      <div class="vx-col w-1/2">
        <vs-input
          class="w-full input-readonly"
          label="PPN"
          name="PPN"
          v-model="dataDN.tax_value"
          readonly
        />
      </div>
    </div>

    <div class="vx-row mb-2">
      <div class="vx-col w-1/3">
        <label class="vs-input--label">Description</label>
      </div>
    </div>

    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-textarea
          class="w-full"
          name="Description"
          v-model="notes"
        />
      </div>
    </div>

    <vs-divider style="width: 100%; margin-left: 2%">Attachment</vs-divider>
    <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%">
      <div class="vx-col sm:w-1/3 w-full">
        <span>File</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <div class="vx-col sm:w-4/5 w-full">
          <input
            id="fileInput"
            name="file"
            class="w-full inputx"
            type="file"
            ref="file"
            multiple="multiple"
            accept=".jpg, .jpeg, .png, .pdf, .xlsx, .xlx, .doc, .docx"
          />
        </div>
      </div>
    </div>

    <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%">
      <table class="vs-table vs-table--tbody-table">
        <template v-for="(tr, i) in fileAttachment">
          <tr
            class="tr-values vs-table--tr tr-table-state-null selected"
            v-bind:key="tr.NameFile"
          >
            <td class="td vs-table--td">{{ tr.NameFile }}</td>
            <td class="td vs-table--td">
              <vx-tooltip text="Show" v-if="tr.PathFile != ''">
                <vs-button
                  type="line"
                  icon-pack="feather"
                  icon="icon-eye"
                  @click.stop="downloadFileAwsS3(tr.PathFile)"
                />
              </vx-tooltip>
            </td>
            <td class="td vs-table--td">
              <template>
                <vx-tooltip text="Delete">
                  <vs-button
                    type="line"
                    icon-pack="feather"
                    icon="icon-trash"
                    @click.stop="handleDeleteAttachment(i, tr.ID)"
                  />
                </vx-tooltip>
              </template>
            </td>
          </tr>
        </template>
      </table>
    </div>

    <br /><br />

    <div class="vx-col sm:w-5/5 w-full mb-2" v-for="(tr, indextr) in items" :key="indextr">
      <template v-if="dataDN.dn_type && dataDN.dn_type.IsUseCoa == 0">
        <div class="vx-row mb-2">
          <div class="vx-col w-2/5">
            <label class="vs-input--label">Operating Unit</label>
          </div>
          <div class="vx-col w-2/5">
            <label class="vs-input--label">Territory</label>
          </div>
          <div class="vx-col w-1/5">
            <label class="vs-input--label"></label>
          </div>
        </div>
        <div class="vx-row mb-2">
          <div class="vx-col w-2/5">
            <vs-input
              class="w-full input-readonly"
              name="Operating Unit"
              v-model="items[indextr].operatingUnit.selected.Name"
              readonly
            />
          </div>
          <div class="vx-col w-2/5">
            <vs-input
              class="w-full input-readonly"
              name="territory"
              v-model="items[indextr].territory.selected.name"
              readonly
            />
          </div>

          <div
            class="vx-col w-1/5"
            v-if="ownership.selected == 'Operating Unit'"
          ></div>
        </div>

        <div class="vx-row mb-2">
          <div class="vx-col w-1/3">
            <label class="vs-input--label">Line Item</label>
          </div>
          <div class="vx-col w-1/3">
            <label class="vs-input--label">VAT OUT</label>
          </div>
          <div class="vx-col w-1/3">
            <label class="vs-input--label">Total DN Value</label>
          </div>
        </div>

        <div class="vx-row mb-2">
          <div class="vx-col w-1/3">
            <vs-input
              v-validate="'required'"
              class="w-full input-readonly"
              readonly
              name="DN Value"
              v-model="items[indextr].dn_value"
              @input="calculate($event, indextr)"
              @keypress="isNumber($event)"
            />
          </div>
          <div class="vx-col w-1/3">
            <vs-input
              class="w-full input-readonly"
              readonly
              v-validate="'required'"
              name="VAT OUT"
              v-model="items[indextr].tax_value"
            />
          </div>
          <div class="vx-col w-1/3">
            <vs-input
              v-validate="'required'"
              class="w-full input-readonly"
              readonly
              name="Total DN Value"
              v-model="items[indextr].total_dn_value"
            />
          </div>
        </div>


      </template>

      <template v-if="dataDN.dn_type && dataDN.dn_type.IsUseCoa == 1">
        <div class="vx-row mb-2">
          <!-- <div class="vx-col w-1/4">
            <label class="vs-input--label">COA</label>
          </div> -->
          <div class="vx-col w-1/3">
            <label class="vs-input--label">Operating Unit</label>
          </div>
          <div class="vx-col w-1/3">
            <label class="vs-input--label">Territory</label>
          </div>
          <div class="vx-col w-1/3">
            <label class="vs-input--label">Cost Centre</label>
          </div>
        </div>
        <div class="vx-row mb-2">
          <!-- <div class="vx-col w-1/4">
            <vs-input
              class="w-full input-readonly"
              name="coa"
              v-model="items[indextr].coa.name"
              readonly
            />
          </div> -->
          
          <div class="vx-col w-1/3">
            <vs-input
              class="w-full input-readonly"
              name="Operating Unit"
              v-model="items[indextr].operatingUnit.selected.Name"
              readonly
            />
          </div>
          <div class="vx-col w-1/3">
            <vs-input
              class="w-full input-readonly"
              name="territory"
              v-model="items[indextr].territory.selected.name"
              readonly
            />
          </div>
          <div class="vx-col w-1/3">
            <vs-input
              class="w-full input-readonly"
              name="Cost Center"
              v-model="items[indextr].costCenter.name"
              readonly
            />
          </div>
        </div>

        <div class="vx-row mb-2">
          <div class="vx-col w-1/4">
            <label class="vs-input--label">Line Item</label>
          </div>
          <div class="vx-col w-1/4">
            <label class="vs-input--label">VAT OUT</label>
          </div>
          <div class="vx-col w-1/4">
            <label class="vs-input--label">Total DN Value</label>
          </div>
          <div class="vx-col w-1/4">
            <label class="vs-input--label"></label>
          </div>
        </div>

        <div class="vx-row mb-2">
          <div class="vx-col w-1/4">
            <vs-input
              v-validate="'required'"
              class="w-full input-readonly"
              readonly
              name="DN Value"
              v-model="items[indextr].dn_value"
              @input="calculate($event, indextr)"
              @keypress="isNumber($event)"
            />
            <span
              class="text-danger text-sm"
              v-if="items[indextr].dn_value == null && status_submit"
              >This field is required</span
            >
          </div>
          <div class="vx-col w-1/4">
            <vs-input
              class="w-full input-readonly"
              readonly
              name="VAT OUT"
              v-model="items[indextr].tax_value"
            />
          </div>
          <div class="vx-col w-1/4">
            <vs-input
              v-validate="'required'"
              class="w-full input-readonly"
              readonly
              name="Total DN Value"
              v-model="items[indextr].total_dn_value"
            />
          </div>
          <div class="vx-col w-1/4" v-if="ownership.selected == 'Operating Unit'">
            <vs-button
                v-if="items[indextr].status=='minus'"
                style="display: table-cell;"
                v-on:click="handleRemoveItem(indextr)"
                color="danger"
                type="filled"
                icon-pack="feather"
                icon="icon-minus"
            ></vs-button>
            <vs-button
                v-else
                style="display: table-cell;"
                v-on:click="handleAddItem()"
                color="success"
                type="filled"
                icon-pack="feather"
                icon="icon-plus"
            ></vs-button>
          </div>
        </div> 
      </template> 

      <div class="vx-row mb-2">
        <div class="vx-col w-1/2">
          <label class="vs-input--label">Note</label>
        </div>
        <div class="vx-col w-1/2">
          <label class="vs-input--label">Assignment</label>
        </div>
      </div>

      <div class="vx-row mb-2">
        <div class="vx-col w-1/2">
          <vs-textarea
            class="w-full"
            name="Note"
            v-model="items[indextr].notes"
          />
        </div>

        <div class="vx-col w-1/2">
          <vs-textarea
            class="w-full"
            name="Assignment"
            v-model="items[indextr].assignments"
          />
        </div>
      </div>

      <vs-divider
        style="width: 100%"
        v-if="indextr != items.length - 1"
      ></vs-divider>
    </div>

    <br /><br /><br />
    <div class="vx-row mb-2">
      <div class="vx-col w-1/3"></div>
      <div class="vx-col w-1/3" style="text-align: right">
        <label class="vs-input--label"><b>Grand Total</b></label>
      </div>
      <div class="vx-col w-1/3">
        <vs-input
          v-validate="'required'"
          class="w-full input-readonly"
          readonly
          name="Grand Total"
          v-model="grand_total"
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-1/3"></div>
      <div class="vx-col w-1/3" style="text-align: right">
        <label class="vs-input--label"><b>Remaining</b></label>
      </div>
      <div class="vx-col w-1/3">
        <vs-input
          v-validate="'required'"
          class="w-full input-readonly"
          readonly
          name="Remaining"
          v-model="remaining"
        />
      </div>
    </div>

    <div class="vx-row mb-6 mt-6">
      <div class="vx-col w-1/4" style="margin-bottom: 150px">
        <vs-button class="mb-2" @click="handleSubmit">Update</vs-button>
      </div>
      <div
        class="vx-col w-1/4"
        style="margin-bottom: 150px"
        v-if="dataDN.source == 'Import Claim' || dataDN.source == 'Manual'"
      >
        <vs-button
          @click="confirm()"
          icon-pack="feather"
          icon="icon-corner-up-left"
          color="red"
        >
          Reverse
        </vs-button>
      </div>
    </div>

    <div>
      <vs-prompt
        color="primary"
        title="Confirmation"
        @cancel="val = ''"
        @accept="doConfirm"
        @close="closeConfirm"
        :active.sync="activePromptConfrm"
      >
        <div class="con-exemple-prompt">
          Are you sure reverse debit note {{ `${dn_number}?` }}
          <br />
          <br />
          <label class="vs-input--label">Revers Date</label>
          <datepicker
            class="w-full"
            v-validate="'required'"
            :inline="false"
            name="Revers Date"
            v-model="revers_date"
            placeholder="Revers Date"
            :disabled-dates="{ from: new Date() }"
          ></datepicker>
          <span
            class="text-danger text-sm"
            v-if="revers_date == '' || revers_date == null"
            >This field is required</span
          >
        </div>
      </vs-prompt>
    </div>
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import moment from "moment";
export default {
  components: {
    Datepicker,
  },
  props: {
    dataDN: {
      type: Object,
    },
  },
  created() {},
  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        search: "",
        offset: 0,
        limit: 10,
        status_submit: false,
        cn_number: null,
        cn_date: new Date(),
        tr_number: null,
        tr_date: null,
        supplier: null,
        optionSupplier: [],
        optionTerritory: [],
        optionCoa: [],
        optionCostCenter: [],
        fileAttachment: [],
        paramsUpload: [],
        dnType: {
          options: [],
          selected: "",
        },
        tax: {
          options: [],
          selected: "",
        },
        optionOperatingUnit: [],
        ownership: {
          options: ["Operating Unit", "Head Office"],
          selected: "",
        },
        settlement: {
          options: ["Payment", "AP Offset"],
          selected: "",
        },
        revers_date: new Date(),
        id: 0,
        dn_number: null,
        reference_code: null,
        assignment_code: null,
        dn_date: null,
        dn_posting_date: null,
        notes: null,
        grand_total: 0,
        remaining: 0,
        tax_value: 0,
        dn_value: 0,
        total_dn: 0,
        ppn: null,
        dpp: 0,
        operatingUnitIDs: [],
        territoryIDs: [],
        dNValues: [],
        taxValues: [],
        totalDNValues: [],
        notes_line: [],
        assignments_line: [],
        coa_id: [],
        cost_center_id: [],
        activePromptConfrm: false,
        items: [
          {
            status: "plus",
            tax_value: 0,
            dn_value: null,
            total_dn_value: 0,
            operatingUnit: {
              selected: "",
            },
            territory: {
              options: [],
              selected: "",
            },
            notes: null,
            assignments: null,
            costCenter: {
              selected: null,
              name : "",
            },
            coa: {
              selected: null,
              name : "",
            },
        }],
      };
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("close");
    },
    dateFormat(value) {
      return moment(String(value)).format("DD MMM YYYY");
    },
    dateFormatBanding(value) {
      return moment(String(value)).format("YYYYMMDD");
    },
    paramData() {
      return {
        notes: this.notes,
        settlement_method : this.settlement.selected,
        dn_reference_code : this.reference_code,
        dn_id: this.dataDN.dn_id,
        notes_line : this.notes_line,
        assignments_line : this.assignments_line,
      };
    },
    handleSubmit() {
      this.status_submit = true;
      this.$validator.validateAll().then((result) => {

        var _this = this
        
        this.items.forEach(function(element, index){
          _this.notes_line[index] = _this.items[index].notes
          _this.assignments_line[index] = _this.items[index].assignments
        })

        if (result) {
          this.$vs.loading();
          this.putData();
        }
      });
    },
    putData() {
      this.$http
        .put("/api/v1/debit-note/manual-approved", this.paramData())
        .then((resp) => {
          if (resp.code == 200) {
            this.id = resp.data.ID;
            if (this.$refs.file.files.length > 0) {
              this.file = this.$refs.file.files;
              for (let i = 0; i < this.$refs.file.files.length; i++) {
                this.uploadData(i);
              }
              document.querySelector("#fileInput").value = "";
            }

            this.$vs.loading.close();
            this.handleClose();
            if (this.fileAttachment.ID) {
              this.$vs.notify({
                color: "success",
                title: "Success",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            }
          } else {
            this.$vs.loading.close();
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    paramUpload(array) {
      var form = new FormData();
      form.append("id", this.dataDN.dn_id);
      form.append("file", this.file[array]);
      return form;
    },
    uploadData(array) {
      this.$http
        .post("/api/v1/debit-note/upload-file", this.paramUpload(array), {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.fileAttachment.push({
              ID: resp.data.attachment.ID,
              NameFile: resp.data.attachment.NameFile,
              PathFile: resp.data.attachment.PathFile,
            });
          } else {
            this.$vs.loading.close();
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleDeleteAttachment(index, id) {
      this.fileAttachment = this.fileAttachment.filter((item, i) => i != index);
      this.deleteFile(id);
      console.log(this.fileAttachment);
    },
    deleteFile(id) {
      this.$vs.loading();
      var form = new FormData();
      form.append("id", id);
      this.$http
        .post("/api/v1/debit-note/delete-file", form, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    confirm() {
      this.activePromptConfrm = true;
    },
    closeConfirm() {
      this.activePromptConfrm = false;
    },
    doConfirm() {
      var gas = true;
      var format_revers_date = this.dateFormatBanding(this.revers_date);
      var format_posting_date = this.dateFormatBanding(
        this.dataDN.posting_date
      );
      if (format_revers_date < format_posting_date) {
        gas = false;
      }

      console.log("format_revers_date", format_revers_date);
      console.log("format_posting_date", format_posting_date);

      if (gas) {
        this.$vs.loading();
        this.$http
          .post("/api/v1/debit-note/reversal-claim/" + this.dataDN.dn_id, {
            reversal_date: this.revers_date,
          })
          .then((resp) => {
            this.$vs.loading.close();
            if (resp.code == 200) {
              this.handleClose();
              this.$vs.notify({
                color: "success",
                title: "Success",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            } else {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            }
          })
          .catch((error) => {
            this.$vs.loading.close();
            console.log(error);
          });
      } else {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Revers date must greater then posting date",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
      }
    },
    getOptionSupplier() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/suppliers", {
          params: {
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.optionSupplier = resp.data.records;
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Supplier option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    getOptionDebitNoteType(isHeadOffice) {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/debit-note-type", {
          params: {
            is_head_office: isHeadOffice,
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.dnType.options = resp.data.records;
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get debit note type option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    getOptionTax() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/tax", {
          params: {
            order: "is_default",
            sort: "desc, name asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.tax.options = resp.data.records;
            this.tax.selected = resp.data.records[0];

            var _this = this;
            this.items.forEach(function (element, index) {
              _this.calculate(_this.items[index].total_dn_value, index);
            });
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get tax option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    getOptionOperatingUnit(isHeadOffice) {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/operating-unit", {
          params: {
            is_head_office: isHeadOffice,
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.optionOperatingUnit = [];
            this.optionOperatingUnit = resp.data.records;
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get operating unit option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    handleAddItem(){
      this.items.splice(this.items.length+1, 0, {
          status: "minus",
          tax_value : 0,
          dn_value : null,
          total_dn_value : 0,
          operatingUnit:{
              selected: "",
          },
          territory: {
            options: [],
            selected: ""
          },
          notes: null,
          assignments: null,
      })

    },
    handleRemoveItem(indextr) {
      this.items.splice(indextr, 1);
    },
    dnTypeChange(val) {
      if (val.IsTax == 1) {
        this.getOptionTax();
      } else {
        this.tax = {
          options: [],
          selected: "",
        };
      }
    },
    onSelectTax(val) {
      if (val) {
        this.tax.selected = val;
        var _this = this;
        this.items.forEach(function (element, index) {
          _this.calculate(_this.items[index].dn_value, index);
        });
      }
    },
    ownershipChange(val) {
      var _this = this;
      this.items.forEach(function (element, index) {
        _this.items[index].operatingUnit.selected = null;
      });
      if (val == "Head Office") {
        this.getOptionOperatingUnit("true");
        this.getOptionDebitNoteType("true");
        const itemLength = this.items.length;
        this.items.splice(1, itemLength - 1);
      } else {
        this.getOptionOperatingUnit("false");
        this.getOptionDebitNoteType("false");
      }
    },
    onSelectOu(operating_unit_id, indextr, source) {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/operating-unit-territory", {
          params: {
            operating_unit_id: operating_unit_id,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            var _this = this;
            _this.items[indextr].territory.options = [];
            if (source != "") {
              _this.items[indextr].territory.selected = null;
            }

            resp.data.records.forEach(function (e) {
              _this.items[indextr].territory.options.push(e.Territory);
            });
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Territory option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    setOperatingUnitSelected(indextr, id) {
      this.$vs.loading();
      this.$http.get("/api/v1/master/operating-unit/" + id).then((resp) => {
        if (resp.status == "success") {
          this.items[indextr].operatingUnit.selected = resp.data;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
    setTerritorySelected(indextr, id) {
      this.$vs.loading();
      this.$http.get("/api/v1/master/territory/" + id).then((resp) => {
        if (resp.status == "success") {
          this.items[indextr].territory.selected = resp.data;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
    setCoaSelected(indextr, id) {
        this.$vs.loading();
        this.$http.get("/api/v1/master/chart-of-account/" + id).then((resp) => {
            if (resp.status == "success") {
            this.items[indextr].coa.selected = resp.data[0].coa;
            this.items[indextr].coa.name = resp.data[0].coa.Code+" - "+resp.data[0].coa.Name;
            this.$vs.loading.close();
            } else {
            this.$vs.loading.close();
            }
        });
    },
    setCostCenterSelected(indextr, id) {
        this.$vs.loading();
        this.$http.get("/api/v1/master/cost-center/" + id).then((resp) => {
            if (resp.status == "success") {
            this.items[indextr].costCenter.selected = resp.data;
            this.items[indextr].costCenter.name = resp.data.Code+" - "+resp.data.Name;
            this.$vs.loading.close();
            } else {
            this.$vs.loading.close();
            }
        });
    }, 
    calculate(val, indextr) {
      if (val != "") {
        var _this = this;
        var grand_total_dn = 0;
        var total_tax = 0;
        var total_dn = 0;
        console.log("calculate", val);
        val = parseFloat(val.replace(/,/g, ""));

        if (this.tax.selected) {
          this.items[indextr].tax_value = (this.tax.selected.Rate * val) / 100;
          this.items[indextr].total_dn_value =
            this.items[indextr].tax_value + val;
        } else {
          this.items[indextr].total_dn_value = val;
        }

        this.items.forEach(function (element, index) {
          grand_total_dn =
            grand_total_dn +
            parseFloat(
              _this.items[index].total_dn_value.toString().replace(/,/g, "")
            );
          total_tax =
            total_tax +
            parseFloat(
              _this.items[index].tax_value.toString().replace(/,/g, "")
            );
          total_dn = total_dn + val;
        });

        this.items[indextr].tax_value = this.items[indextr].tax_value
          .toString()
          .replace(/,/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.items[indextr].dn_value = this.items[indextr].dn_value
          .toString()
          .replace(/,/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.items[indextr].total_dn_value = this.items[indextr].total_dn_value
          .toString()
          .replace(/,/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        this.grand_total = parseFloat(grand_total_dn).toFixed(2).toString().replace(/,/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        this.dn_value = parseFloat(total_dn).toFixed(2).toString()
        this.tax_value = parseFloat(total_tax).toFixed(2).toString()
        this.total_dn = parseFloat(this.grand_total).toFixed(2).toString().replace(/,/g, '')
      }
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
  mounted() {
    this.getOptionSupplier();
    if (this.dataDN) {
      this.$nextTick(() => {
        // console.log(this.dataDN, "this.dataDN");
        this.dn_number = this.dataDN.dn_code;
        this.supplier = this.dataDN.supplier_code+" - "+this.dataDN.supplier_name;
        this.ownership.selected = this.dataDN.ownership;
        this.dnType.selected =
          this.dataDN.dn_type.ID == 0 ? "" : this.dataDN.dn_type.Name;
        this.reference_code = this.dataDN.dn_reference_code;
        this.dn_date = this.dateFormat(this.dataDN.dn_date);
        this.assignment_code = this.dataDN.assignment_code;
        this.dn_posting_date = this.dateFormat(this.dataDN.posting_date);
        this.cn_number = this.dataDN.cn_number;
        this.tax.selected = this.dataDN.tax.ID == 0 ? "" : this.dataDN.tax.Name;
        this.cn_date = this.dateFormat(this.dataDN.cn_date);
        this.settlement.selected = this.dataDN.settlement;
        this.notes = this.dataDN.notes;
        this.grand_total = this.dataDN.total_dn.toFixed(2).toString().replace(/,/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        this.fileAttachment = this.dataDN.attachment
        this.notes = this.dataDN.notes
        this.remaining = this.dataDN.remaining_dn_value.toFixed(2)
          .toString()
          .replace(/,/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        
        this.ownershipChange(this.dataDN.ownership);
        var _this = this
        this.items = [];
        var statusAdd;
        for(var k = 0; k < this.dataDN.debit_note_ownerships.length; k++){
          if(this.dataDN.ownership != "Head Office"){
            if(k == 0){
                statusAdd = "plus"
            }else{
                statusAdd = "minus"
            }
          }

          console.log(this.dataDN.debit_note_ownerships, "disini notenya");

          _this.items.push({
              status: statusAdd,
              tax_value : this.dataDN.debit_note_ownerships[k].TaxValue.toFixed(2).toString().replace(/,/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              dn_value : this.dataDN.debit_note_ownerships[k].DnValue.toFixed(2).toString().replace(/,/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              total_dn_value : this.dataDN.debit_note_ownerships[k].TotalDnValue.toFixed(2).toString().replace(/,/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              operatingUnit:{
                  selected: "",
              },
              territory: {
                options: [],
                selected: null
              },
              notes : this.dataDN.debit_note_ownerships[k].Notes,
              assignments : this.dataDN.debit_note_ownerships[k].Assignment,
              costCenter: {
                selected: null,
                name: "",
              },
              coa: {
                selected: null,
                name: "",
              },
          })

          if (this.dataDN.debit_note_ownerships[k].OperatingUnitID) {
            this.onSelectOu(
              this.dataDN.debit_note_ownerships[k].OperatingUnitID,
              k,
              ""
            );
            this.setOperatingUnitSelected(
              k,
              this.dataDN.debit_note_ownerships[k].OperatingUnitID
            );
          }

          if (this.dataDN.debit_note_ownerships[k].TerritoryID) {
            this.setTerritorySelected(
              k,
              this.dataDN.debit_note_ownerships[k].TerritoryID
            );
          }

          // if (this.dataDN.debit_note_ownerships[k].CoaID) {
          //   this.setCoaSelected(k, this.dataDN.debit_note_ownerships[k].CoaID);
          // }

          if (this.dataDN.debit_note_ownerships[k].CostCenterID) {
            this.setCostCenterSelected(k, this.dataDN.debit_note_ownerships[k].CostCenterID);
          }
        }
      });
    }
  },
  computed: {},
  watch: {
    dataDN() {
      Object.assign(this.$data, this.initialState());
      this.$nextTick(() => {
        this.dn_number = this.dataDN.dn_code;
        this.supplier = this.dataDN.supplier_code+" - "+this.dataDN.supplier_name;
        this.ownership.selected = this.dataDN.ownership;
        this.dnType.selected =
          this.dataDN.dn_type.ID == 0 ? "" : this.dataDN.dn_type.Name;
        this.reference_code = this.dataDN.dn_reference_code;
        this.dn_date = this.dateFormat(this.dataDN.dn_date);
        this.assignment_code = this.dataDN.assignment_code;
        this.dn_posting_date = this.dateFormat(this.dataDN.posting_date);
        this.cn_number = this.dataDN.cn_number;
        this.tax.selected = this.dataDN.tax.ID == 0 ? "" : this.dataDN.tax.Name;
        this.cn_date = this.dateFormat(this.dataDN.cn_date);
        this.settlement.selected = this.dataDN.settlement;
        this.grand_total = this.dataDN.total_dn
          .toString()
          .replace(/,/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.remaining = this.dataDN.remaining_dn_value
          .toString()
          .replace(/,/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.fileAttachment = this.dataDN.attachment;

        this.ownershipChange(this.dataDN.ownership);
        var _this = this;
        this.items = [];
        var statusAdd;
        for (var k = 0; k < this.dataDN.debit_note_ownerships.length; k++) {
          if (this.dataDN.ownership != "Head Office") {
            if (k == 0) {
              statusAdd = "plus";
            } else {
              statusAdd = "minus";
            }
          }

          _this.items.push({
              status: statusAdd,
              tax_value : this.dataDN.debit_note_ownerships[k].TaxValue.toFixed(2).toString().replace(/,/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              dn_value : this.dataDN.debit_note_ownerships[k].DnValue.toFixed(2).toString().replace(/,/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              total_dn_value : this.dataDN.debit_note_ownerships[k].TotalDnValue.toFixed(2).toString().replace(/,/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              operatingUnit:{
                  selected: "",
              },
              territory: {
                options: [],
                selected: ""
              },
              notes : this.dataDN.debit_note_ownerships[k].NotesLine,
              assignments : this.dataDN.debit_note_ownerships[k].Assignment,
              costCenter: {
                selected: null,
                name: "",
              },
              coa: {
                selected: null,
                name: "",
              },
          })

          if (this.dataDN.debit_note_ownerships[k].OperatingUnitID) {
            this.onSelectOu(
              this.dataDN.debit_note_ownerships[k].OperatingUnitID,
              k,
              ""
            );
          }

          // if (this.dataDN.debit_note_ownerships[k].CoaID) {
          //   this.setCoaSelected(k, this.dataDN.debit_note_ownerships[k].CoaID);
          // }

          if (this.dataDN.debit_note_ownerships[k].CostCenterID) {
            this.setCostCenterSelected(k, this.dataDN.debit_note_ownerships[k].CostCenterID);
          }
        }
      });
    },
  },
};
</script>
<style scoped>
.pagination {
  display: flex;
  margin: 0.25rem 0.25rem 0;
}

.pagination button {
  flex-grow: 1;
}

.pagination button:hover {
  cursor: pointer;
}

.horizontal-divider {
  border-top: 1px solid #c1bbff;
  padding: 5px;
  margin-top: 15px;
}
</style>
