<style>
.input-readonly > div > .vs-inputx {
  background-color: #e6e6e6;
}
</style> 
<template>
  <div class="vx-col w-5/6 ml-auto mr-auto">
    <span>
      <h4 class="mb-5">Debit Note Claim</h4>
    </span>
    <div class="vx-row mb-2">
      <div class="vx-col w-1/2">
        <vs-input
          class="w-full input-readonly"
          label="Supplier"
          name="Supplier"
          v-model="supplierView"
          readonly
        />
      </div>
       <div class="vx-col w-1/2">
        <vs-input
          class="w-full input-readonly"
          label="Dn Number"
          name="Dn Number"
          v-model="dn_number"
          readonly
        />
       </div>
    </div>
    
    <div class="vx-row mb-2">
      <div class="vx-col w-1/2">
        <vs-input
          class="w-full input-readonly"
          label="Ownership"
          name="Ownership"
          v-model="ownership.selected"
          readonly
        />
      </div>
      <div class="vx-col w-1/2">
        <vs-input
            class="w-full input-readonly"
            label="DN Type"
            name="DN Type"
            v-model="dnType.selected.Name"
            readonly
          />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-1/2">
        <vs-input
            class="w-full input-readonly"
            readonly
          label="Reference Code"
          name="Reference Code"
          v-model="reference_code"
          placeholder="Reference Code"
        />
      </div>
      <div class="vx-col w-1/2">
        <vs-input
          class="w-full input-readonly"
          label="Dn Date"
          name="Dn Date"
          v-model="dn_date"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-1/2">
        <vs-input
            class="w-full input-readonly"
            readonly
          label="Assignment Code"
          name="Assignment Code"
          v-model="assignment_code"
          placeholder="Assignment Code"
        />
      </div>
      <div class="vx-col w-1/2">
        <vs-input
          class="w-full input-readonly"
          label="Dn Posting Date"
          name="Dn Posting Date"
          v-model="dn_posting_date"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-1/2">
        <vs-input
          class="w-full input-readonly"
          readonly
          label="Supplier CN/SOA Code"
          name="Supplier CN/SOA Code"
          v-model="cn_number"
          placeholder="Supplier CN/SOA Code"
        />
      </div>
      <div class="vx-col w-1/2">
        <vs-input
          class="w-full input-readonly"
          label="VAT OUT"
          name="VAT OUT"
          v-model="tax.selected.Name"
          readonly
        />
      </div>
    </div>
    
    <div class="vx-row mb-2">
      <div class="vx-col w-1/2">     
        <vs-input
          class="w-full input-readonly"
          label="Supplier CN Date"
          name="Supplier CN Date"
          v-model="cn_date"
          readonly
        />  
      </div>
      <div class="vx-col w-1/2">
        <vs-input
          class="w-full input-readonly"
          label="Settlement Method"
          name="Settlement Method"
          v-model="settlement.selected"
          readonly
        />  
      </div>
    </div>
    
    
    <div class="vx-row mb-2">
      <div class="vx-col w-1/2">    
      </div>
      <div class="vx-col w-1/2">
        <vs-input
          class="w-full input-readonly"
          label="Reversal Date"
          name="Reversal Date"
          v-model="dn_reversal_date"
          readonly
        />  
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-textarea
          class="w-full input-readonly"
          readonly
          label="Note"
          name="Note"
          v-model="notes"
          placeholder="Note"
        />
      </div>
    </div>

    
    <vs-divider style="width: 100%; margin-left: 2%">Attachment</vs-divider>
    <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%">
        <table class="vs-table vs-table--tbody-table">
          <template v-for="(tr) in dataDN.attachment">
            <tr
              class="tr-values vs-table--tr tr-table-state-null selected"
              v-bind:key="tr.NameFile"
            >
              <td class="td vs-table--td">{{ tr.NameFile }}</td>
              <td class="td vs-table--td">
                <vx-tooltip text="Show" v-if="tr.PathFile != ''">
                  <vs-button
                    type="line"
                    icon-pack="feather"
                    icon="icon-eye"
                    @click.stop="downloadFileAwsS3(tr.PathFile)"
                  />
                </vx-tooltip>
              </td>
            </tr>
        </template>
      </table>
    </div>

    <br><br>

    <div class="vx-col sm:w-5/5 w-full mb-2" v-for="(tr, indextr) in items" :key="indextr">
      <div class="vx-row mb-2">
        <div class="vx-col w-2/5">
          <label class="vs-input--label">Operating Unit</label>
        </div>
        <div class="vx-col w-2/5">
          <label class="vs-input--label">Territory</label>
        </div>
        <div class="vx-col w-1/5">
          <label class="vs-input--label"></label>
        </div>
      </div>
      <div class="vx-row mb-2">
        <div class="vx-col w-2/5">
          <vs-input
            class="w-full input-readonly"
            name="Operating Unit"
            v-model="items[indextr].operatingUnit.selected.Name"
            readonly
          /> 
        </div>
        <div class="vx-col w-2/5">
          <vs-input
            class="w-full input-readonly"
            name="territory"
            v-model="items[indextr].territory.selected.name"
            readonly
          />
         
        </div>
        
        <div class="vx-col w-1/5" v-if="ownership.selected == 'Operating Unit'">
          
        </div>
      </div>
      
      <div class="vx-row mb-2">
        <div class="vx-col w-1/3">
          <label class="vs-input--label">Line Item</label>
        </div>
        <div class="vx-col w-1/3">
          <label class="vs-input--label">VAT OUT</label>
        </div>
        <div class="vx-col w-1/3">
          <label class="vs-input--label">Total DN Value</label>
        </div>
      </div>

      <div class="vx-row mb-2">
        <div class="vx-col w-1/3">
          <vs-input
            v-validate="'required'"
            class="w-full input-readonly"
            readonly
            name="DN Value"
            v-model="items[indextr].dn_value"
            @input="calculate($event, indextr)"
            @keypress="isNumber($event)"
          />
        </div>
        <div class="vx-col w-1/3">
          <vs-input
            class="w-full input-readonly"
            readonly
            v-validate="'required'"
            name="VAT OUT"
            v-model="items[indextr].tax_value"
          />
        </div>
        <div class="vx-col w-1/3">
          <vs-input
            v-validate="'required'"
            class="w-full input-readonly"
            readonly
            name="Total DN Value"
            v-model="items[indextr].total_dn_value"
          />
        </div>
      </div>  
    </div>

    <br><br><br>
    <div class="vx-row mb-2">
      <div class="vx-col w-1/3">
      </div>
      <div class="vx-col w-1/3" style="text-align:right">        
          <label class="vs-input--label"><b>Grand Total</b></label>
      </div>
      <div class="vx-col w-1/3">
        <vs-input
          v-validate="'required'"
          class="w-full input-readonly"
          readonly
          name="Grand Total"
          v-model="grand_total"
        />
      </div>
    </div>


  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import moment from "moment"
export default {
  components: {
    Datepicker,
  },
  props: {
    dataDN: {
      type: Object,
    },
  },
  created() {},
  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        search: "",
        offset: 0,
        limit: 10,
        status_submit: false,
        cn_number: null,
        cn_date: null,
        tr_number: null,
        tr_date: null,
        supplier: null,
        optionSupplier: [],
        optionTerritory: [],
        notes: null,
        fileAttachment: [],
        paramsUpload:[],
        dnType: {
          options: [],
          selected: ""
        },
        tax: {
          options: [],
          selected: ""
        },
        optionOperatingUnit: [],
        ownership: {
          options: ["Operating Unit","Head Office"],
          selected: ""
        },
        settlement: {
          options: ["Payment", "AP Offset"],
          selected: ""
        },
        id: 0,
        dn_number: null,
        reference_code: null,
        assignment_code: null,
        dn_date: new Date(),
        dn_posting_date: new Date(),   
        dn_reversal_date: new Date(),  
        grand_total: 0,      
        tax_value: 0,     
        dn_value: 0,
        total_dn: 0,
        operatingUnitIDs: [],      
        territoryIDs: [],    
        dNValues: [],    
        taxValues: [],    
        totalDNValues: [],  
        items: [{
            status: "plus",
            tax_value : 0,
            dn_value : null,
            total_dn_value : 0,
            operatingUnit:{
                selected: "",
            },
            territory: {
              options: [],
              selected: ""
            },
        }],
        supplierView: "",
      };
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("close");
    },
    paramData() {
      return {
        //dn_number: this.dn_number,
        territory_id: this.$userLogin.territory_id,
        territory_area_id: this.$userLogin.territory_area_id,
        supplier_address: this.supplier.Address,
        supplier_city: this.supplier.City,
        supplier_code: this.supplier.Code,
        supplier_contact_name: this.supplier.ContactName,
        supplier_name: this.supplier.Name,
        supplier_phone: this.supplier.Phone,
        supplier_id: this.supplier.ID,
        //territory_code: this.territory.code,
        //territory_id: this.territory.ID,
        //territory_name: this.territory.name,
        supplier_cn_number: this.cn_number,
        supplier_cn_date: this.cn_date,
        //tax_return_number: this.tr_number,
        //tax_return_date: this.tr_date,
        dn_value: this.dn_value,
        tax_value: this.tax_value,
        total_dn: this.total_dn,
        type: this.dnType.selected.Name,
        notes: this.notes,
        ownership: this.ownership.selected,
        debit_note_type_id: this.dnType.selected.ID,
        assignment_code : this.assignment_code,
        settlement_method : this.settlement.selected,
        dn_reference_code : this.reference_code,
        tax_id : this.tax.selected.ID,
        dn_date: this.dn_date,
        posting_date : this.dn_posting_date,
        operating_unit_ids : this.operatingUnitIDs,
        territory_ids : this.territoryIDs,
        dn_values : this.dNValues,
        tax_values : this.taxValues,
        total_dn_values : this.totalDNValues,
        dn_id: this.dataDN.dn_id,
      };
    },
    handleSubmit() {
      this.status_submit = true;
      this.$validator.validateAll().then((result) => {

        var _this = this
        
        this.items.forEach(function(element, index){
            _this.operatingUnitIDs[index] = _this.items[index].operatingUnit.selected.ID
            _this.territoryIDs[index] = _this.items[index].territory.selected.ID
            _this.dNValues[index] = _this.items[index].dn_value
            _this.taxValues[index] = _this.items[index].tax_value
            _this.totalDNValues[index] = _this.items[index].total_dn_value
   
        })

        if (result) {
          console.log(this.paramData());
          this.$vs.loading();
          this.putData();
        }
      });
    },
    putData() {
      this.$http
        .put("/api/v1/debit-note/manual", this.paramData())
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.id = resp.data.ID
            if (this.$refs.file.files.length > 0) {
              this.file = this.$refs.file.files;
              for(let i =0; i < this.$refs.file.files.length; i++) {
                this.uploadData(i)
              }
              document.querySelector("#fileInput").value = "";
            }
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    paramUpload(array) {
      var form = new FormData();
      form.append("id", this.id);
      form.append("file", this.file[array]);
      return form;
    },
    uploadData(array) {
      this.$vs.loading();
      this.$http
        .post("/api/v1/debit-note/upload-file", this.paramUpload(array), {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          this.fileAttachment.push({
            id: resp.data.attachment.ID,
            name: resp.data.attachment.NameFile,
            path: resp.data.attachment.PathFile,
          });
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    getOptionSupplier() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/suppliers", {
          params: {
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.optionSupplier = resp.data.records;
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Supplier option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    getOptionDebitNoteType() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/debit-note-type", {
          params: {
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.dnType.options = resp.data.records;
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get debit note type option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    getOptionTax() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/tax", {
          params: {
            order: "is_default",
            sort: "desc, name asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.tax.options = resp.data.records;
            this.tax.selected = resp.data.records[0];
        
            var _this = this
            this.items.forEach(function(element, index){
              _this.calculate(_this.items[index].total_dn_value, index)
            })
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get tax option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    getOptionOperatingUnit(isHeadOffice) {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/operating-unit", {
          params: {
            is_head_office: isHeadOffice,
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.optionOperatingUnit = []
            this.optionOperatingUnit = resp.data.records;
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get operating unit option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    handleAddItem(){
      this.items.splice(this.items.length+1, 0, {
          status: "minus",
          tax_value : 0,
          dn_value : null,
          total_dn_value : 0,
          operatingUnit:{
              selected: "",
          },
          territory: {
            options: [],
            selected: ""
          },
      })
      
    },
    handleRemoveItem(indextr){
      this.items.splice(indextr, 1)
    },
    dnTypeChange(val){
      if (val.IsTax == 1){
        this.getOptionTax();
      }else{        
        this.tax = {
          options: [],
          selected: ""
        }
      }
    },
    onSelectTax(val){
      if (val){
        this.tax.selected = val
        var _this = this
        this.items.forEach(function(element, index){
          _this.calculate(_this.items[index].dn_value, index)
        })
      }
    },
    ownershipChange(val){
      var _this = this
      this.items.forEach(function(element, index){
        _this.items[index].operatingUnit.selected = null
      })
      if(val == "Head Office"){
        this.getOptionOperatingUnit("true");
        const itemLength = this.items.length
        this.items.splice(1, itemLength-1)
      }else{
        this.getOptionOperatingUnit("false");
      }      
    },
    onSelectOu(operating_unit_id, indextr, source) {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/operating-unit-territory", {
          params: {
            operating_unit_id : operating_unit_id,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
              var _this = this
              _this.items[indextr].territory.options = []
              if (source != ""){
                _this.items[indextr].territory.selected = null
              }
             
              resp.data.records.forEach(function(e){
                _this.items[indextr].territory.options.push(e.Territory);
              })
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Territory option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    setOperatingUnitSelected(indextr, id) {
        this.$vs.loading();
        this.$http.get("/api/v1/master/operating-unit/" + id).then((resp) => {
            if (resp.status == "success") {
            this.items[indextr].operatingUnit.selected = resp.data;       
            this.$vs.loading.close();
            } else {
            this.$vs.loading.close(); 
            }
        });
    },
    setTerritorySelected(indextr, id) {
        this.$vs.loading();
        this.$http.get("/api/v1/master/territory/" + id).then((resp) => {
            if (resp.status == "success") {
            this.items[indextr].territory.selected = resp.data;    
            this.$vs.loading.close();
            } else {
            this.$vs.loading.close(); 
            }
        });
    },
    calculate(val, indextr) {
      if (val != ""){
        var _this = this
        var grand_total_dn = 0
        var total_tax = 0
        var total_dn = 0
        console.log("calculate", val)
        val = parseFloat(val.replace(/,/g, ''))

        if (this.tax.selected) {
          this.items[indextr].tax_value = this.tax.selected.Rate * val / 100
          this.items[indextr].total_dn_value = this.items[indextr].tax_value + val
        }else {
          this.items[indextr].total_dn_value = val
        }

        this.items.forEach(function(element, index){
          grand_total_dn = grand_total_dn + parseFloat(_this.items[index].total_dn_value.toString().replace(/,/g, ''))
          total_tax = total_tax + parseFloat(_this.items[index].tax_value.toString().replace(/,/g, ''))
          total_dn = total_dn + val
        })

        this.items[indextr].tax_value = this.items[indextr].tax_value.toString().replace(/,/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        this.items[indextr].dn_value = this.items[indextr].dn_value.toString().replace(/,/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        this.items[indextr].total_dn_value = this.items[indextr].total_dn_value.toString().replace(/,/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');

        this.grand_total = parseFloat(grand_total_dn).toFixed(2).toString().replace(/,/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        this.dn_value = parseFloat(total_dn).toFixed(2).toString()
        this.tax_value = parseFloat(total_tax).toFixed(2).toString()
        this.total_dn = parseFloat(this.grand_total).toFixed(2).toString().replace(/,/g, '')
      }
      
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    dateFormat(value) {
      return moment(String(value)).format("DD MMM YYYY");
    },
    
  },
  mounted() {
    this.getOptionSupplier();
    this.getOptionDebitNoteType();
    if (this.dataDN) {
      this.$nextTick(() => {
        this.dn_number = this.dataDN.dn_code;
        this.supplier = this.dataDN.supplier;
        this.supplierView = this.dataDN.supplier_code+" - "+this.dataDN.supplier_name;
        this.ownership.selected = this.dataDN.ownership;
        this.dnType.selected = this.dataDN.dn_type;
        this.reference_code = this.dataDN.dn_reference_code;
        this.dn_date = this.dateFormat(this.dataDN.dn_date);
        this.assignment_code = this.dataDN.assignment_code;
        this.dn_posting_date = this.dateFormat(this.dataDN.posting_date);
        this.dn_reversal_date = this.dateFormat(this.dataDN.reversal_date);
        this.cn_number = this.dataDN.cn_number;
        this.tax.selected = this.dataDN.tax;
        this.cn_date = this.dateFormat(this.dataDN.cn_date);
        this.settlement.selected = this.dataDN.settlement;
        this.notes = this.dataDN.notes;
        this.grand_total = this.dataDN.total_dn.toFixed(2).toString().replace(/,/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        
        this.ownershipChange(this.dataDN.ownership);
        var _this = this
        this.items = [];
        var statusAdd;
        for(var k = 0; k < this.dataDN.debit_note_ownerships.length; k++){
          if(this.dataDN.ownership != "Head Office"){
            if(k == 0){
                statusAdd = "plus"
            }else{
                statusAdd = "minus"
            }
          }

          _this.items.push({
              status: statusAdd,
              tax_value : this.dataDN.debit_note_ownerships[k].TaxValue.toFixed(2).toString().replace(/,/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              dn_value : this.dataDN.debit_note_ownerships[k].DnValue.toFixed(2).toString().replace(/,/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              total_dn_value : this.dataDN.debit_note_ownerships[k].TotalDnValue.toFixed(2).toString().replace(/,/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              operatingUnit:{
                  selected: "",
              },
              territory: {
                options: [],
                selected: null
              },
          })           
          
          if (this.dataDN.debit_note_ownerships[k].OperatingUnitID) {
            this.onSelectOu(this.dataDN.debit_note_ownerships[k].OperatingUnitID, k, "")
            this.setOperatingUnitSelected(k, this.dataDN.debit_note_ownerships[k].OperatingUnitID);
          }
          
          if (this.dataDN.debit_note_ownerships[k].TerritoryID) {
            this.setTerritorySelected(k, this.dataDN.debit_note_ownerships[k].TerritoryID);
          }
        }
      });
    }
  },
  computed: {},
  watch: {
    dataDN() {
      Object.assign(this.$data, this.initialState());
      this.$nextTick(() => {
        this.dn_number = this.dataDN.dn_code;
        this.supplier = this.dataDN.supplier;
        this.supplierView = this.dataDN.supplier_code+" - "+this.dataDN.supplier_name;
        this.ownership.selected = this.dataDN.ownership;
        this.dnType.selected = this.dataDN.dn_type;
        this.reference_code = this.dataDN.dn_reference_code;
        this.dn_date = this.dateFormat(this.dataDN.dn_date);
        this.assignment_code = this.dataDN.assignment_code;
        this.dn_posting_date = this.dateFormat(this.dataDN.posting_date);
        this.dn_reversal_date = this.dateFormat(this.dataDN.reversal_date);
        this.cn_number = this.dataDN.cn_number;
        this.tax.selected = this.dataDN.tax;
        this.cn_date = this.dateFormat(this.dataDN.cn_date);
        this.settlement.selected = this.dataDN.settlement;
        this.notes = this.dataDN.notes;
        this.grand_total = this.dataDN.total_dn.toFixed(2).toString().replace(/,/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        
        this.ownershipChange(this.dataDN.ownership);
        var _this = this
        this.items = [];
        var statusAdd;
        for(var k = 0; k < this.dataDN.debit_note_ownerships.length; k++){

          if(this.dataDN.ownership != "Head Office"){
            if(k == 0){
                statusAdd = "plus"
            }else{
                statusAdd = "minus"
            }
          }

          _this.items.push({
              status: statusAdd,
              tax_value : this.dataDN.debit_note_ownerships[k].TaxValue.toFixed(2).toString().replace(/,/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              dn_value : this.dataDN.debit_note_ownerships[k].DnValue.toFixed(2).toString().replace(/,/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              total_dn_value : this.dataDN.debit_note_ownerships[k].TotalDnValue.toFixed(2).toString().replace(/,/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              operatingUnit:{
                  selected: "",
              },
              territory: {
                options: [],
                selected: ""
              },
          })           
          
          if (this.dataDN.debit_note_ownerships[k].OperatingUnitID) {
            this.onSelectOu(this.dataDN.debit_note_ownerships[k].OperatingUnitID, k, "")
            this.setOperatingUnitSelected(k, this.dataDN.debit_note_ownerships[k].OperatingUnitID);
          }
          
          if (this.dataDN.debit_note_ownerships[k].TerritoryID) {
            this.setTerritorySelected(k, this.dataDN.debit_note_ownerships[k].TerritoryID);
          }
        }
        
      });
    },
  },
};
</script>
<style scoped>
.pagination {
  display: flex;
  margin: 0.25rem 0.25rem 0;
}

.pagination button {
  flex-grow: 1;
}

.pagination button:hover {
  cursor: pointer;
}

.horizontal-divider {
  border-top: 1px solid #c1bbff;
  padding: 5px;
  margin-top: 15px;
}
</style>